import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { mainnet, sepolia } from "wagmi/chains";
import {
  walletConnectWallet,
  metaMaskWallet,
} from "@rainbow-me/rainbowkit/wallets";

const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;
const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;

export const config = getDefaultConfig({
  appName: "Bythen",
  projectId: projectId,
  wallets: [
    {
      groupName: "Popular",
      wallets: [metaMaskWallet, walletConnectWallet],
    },
  ],
  chains: APP_ENV === "production" ? [mainnet] : [sepolia],
  multiInjectedProviderDiscovery: false,
  ssr: true, // If your dApp uses server side rendering (SSR)
});
