import "@/styles/globals.css";
import "@rainbow-me/rainbowkit/styles.css";
import localFont from "next/font/local";
import { Major_Mono_Display } from "next/font/google";

import "regenerator-runtime/runtime";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit";
import { config } from "@/utils/wagmi";
import { GoogleTagManager } from "@next/third-parties/google";
import WalletDisclaimer from "@/components/WalletDisclaimer";
import PageLoader from "@/components/Loader/PageLoader";
import { ReactLenis, useLenis } from "lenis/react";
import { useState } from "react";

const neueMontreal = localFont({
  src: [
    {
      path: "../assets/fonts/NeueMontreal-Regular.otf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../assets/fonts/NeueMontreal-Medium.otf",
      weight: "500",
      style: "normal",
    },
  ],
  variable: "--font-neuemontreal",
});

export const founders = localFont({
  src: [
    {
      path: "../assets/fonts/founders-grotesk-medium.woff2",
      weight: "500",
      style: "normal",
    },
    {
      path: "../assets/fonts/founders-grotesk-condensed-semibold.woff2",
      weight: "600",
      style: "normal",
    },
  ],
  variable: "--font-founders",
});

const ITCAvantGarde = localFont({
  src: [
    {
      path: "../assets/fonts/ITCAvantGardeGothicProMedium.woff",
      weight: "400",
      style: "normal",
    },
  ],
  variable: "--font-itcavantgarde",
});

const MajorMono = Major_Mono_Display({
  weight: "400",
  subsets: ["latin"],
  variable: "--font-majormono",
});

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH;
const GTM_PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW;

const WALLET_STYLE = {
  fonts: {
    body: neueMontreal.style.fontFamily,
  },
};

export default function App({ Component, pageProps }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            gcTime: 0,
            staleTime: 0,
            retry: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
          },
        },
      })
  );

  return (
    <>
      <style jsx global>{`
        :root {
          --blue: #1e90ff;
          --white: #ffffff;
        }

        html {
          font-family: ${neueMontreal.style.fontFamily};
        }
      `}</style>
      <PageLoader />
      <main
        className={`${ITCAvantGarde.variable} ${MajorMono.variable} ${founders.variable} font-sans`}
      >
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider
              appInfo={{
                appName: "Bythen",
                disclaimer: WalletDisclaimer,
              }}
              theme={{
                ...darkTheme(),
                ...WALLET_STYLE,
              }}
              modalSize="compact"
            >
              <GoogleTagManager
                gtmId={GTM_ID}
                auth={GTM_AUTH}
                preview={GTM_PREVIEW}
              />
              <ReactLenis root>
                <Component {...pageProps} />
              </ReactLenis>
            </RainbowKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </main>
    </>
  );
}
