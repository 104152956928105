import DelegateIcon from "@/assets/delegate-icon.png";

export default function WalletDisclaimer({ Link }) {
  return (
    <div className="text-white text-sm flex flex-col lg:flex-row justify-center items-center lg:justify-start lg:items-start">
      <div className="lg:hidden w-[calc(100%+64px)] h-[1px] bg-white/5 mb-5"></div>
      <div className="p-2 lg:p-1 bg-white inline-block h-auto border rounded-[12px] lg:rounded-[6px] max-w-14 lg:max-w-7 lg:mr-3 mt-1">
        <img
          className="min-w-[18px]"
          src={DelegateIcon.src}
          width="60"
          height="60"
        />
      </div>
      <div className="text-center lg:text-left space-y-[8px] lg:space-y-[2px] text-base lg:text-[12px] leading-5 lg:leading-4 mt-2 lg:mt-0">
        <p className="font-bold tracking-wide">Delegate Your Wallet</p>
        <p className="text-white/60">
          You can use your hot wallet instead of your main wallet with{" "}
          <Link href="https://delegate.xyz/">delegate.xyz</Link>. If you have
          set up your wallet, please connect above.
        </p>
      </div>
    </div>
  );
}
